<template>
  <div class="notification">
    <button class="notification__button" v-on:click="$emit('remove', text)"></button>
    <div v-if="text" class="notification__text" v-html="text"></div>
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: {
    text: String
  },
  mounted() {
    setTimeout(() => {
      this.$emit('remove', this.text)
    }, 8000)
  }
}
</script>