<template>
  <div></div>
</template>

<script>
import codesApi from "../components/api/codes"

export default {
  name: "CodesExporter",
  async mounted() {
    let codes = []
    try {
      codes = await codesApi.getAllCodes()
      let csvContent = "data:text/csv;charset=utf-8," + codes.join("\n")
      window.open(encodeURI(csvContent))
    } catch (e) {
      await this.$router.push({name: 'home'})
    }
  }
}
</script>