<template>
  <div class="bg-deco">
    <div class="bg-deco__flours-container">
      <div v-for="i in 6" v-bind:key="'flour_' + i" class="bg-deco__flour-container"
           :class="[{ 'bg-deco__flour-container--right' : i % 2 === 1 }, 'bg-deco__flour-container--' + i]">
        <img class="bg-deco__flour img-fluid" src="../assets/images/mehl.png" :alt="'flour_' + i" >
      </div>
    </div>
    <div class="bg-deco__candies-container">
      <div v-for="i in 10" v-bind:key="'candy_' + i" class="bg-deco__candy-container"
           :class="[{ 'bg-deco__candy-container--right' : i % 2 === 0 }, 'bg-deco__candy-container--' + i]">
        <img class="bg-deco__candy" :src="getRndCandy()" :alt="'candy_' + i" >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackgroundDecoration",
  data() {
    return {
      numberOfCandyTypes: 8
    }
  },
  mounted() {
    Array.from(document.getElementsByClassName('bg-deco__flour')).forEach(item => {
      item.style.transform = `rotate(${ this.getRndInt(0, 360) }deg) scale(0.${this.getRndInt(70, 99) })`
    })
  },
  methods: {
    getRndCandy: function () {
      return require(`../assets/images/decoration/candy_${this.getRndInt(1, this.numberOfCandyTypes)}.png`)
    },
    getRndInt: function (min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  }
}
</script>