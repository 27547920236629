<template>
  <div :class="['form-input', {'form-input--active' : isActive}, {'form-input--error' : inputError}]">
    <label class="form-input__label"
           @click="setFocusForSiblingInput(name)"
           :for="name + '-' + _uid">{{ label }}<span v-if="required" class="form-input__label--required">*</span></label>
    <input class="form-input__input"
           :ref="name" :name="name + '-' + _uid"
           :type="type" :required="required"
           :value="inputValue"
           @input="onInput($event.target.value)"
           @change="validate()"
           @focus="setFocus(true)"
           @blur="setFocus(false)">
    <span v-if="inputError" class="form-input__error" v-html="errorMessage"></span>
  </div>
</template>

<script>

import EmailValidation from "../components/mixins/email-validation";

export default {
  model: {
    prop: 'inputValue'
  },
  mixins: [
    EmailValidation
  ],
  props: {
    inputValue: {
      type: [String, Number]
    },
    errorMessage: {
      type: String
    },
    label: {
      type: String
    },
    name: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  data() {
    return {
      value: '',
      focus: false,
      inputError: false
    }
  },
  watch: {
    inputValue(value) {
      this.value = value;
    }
  },
  computed: {
    isActive() {
      return this.value && this.value.length > 0 || this.focus || this.value && this.validated
    },
    validated() {
      let valid = true

      if (this.required) {
        valid = this.value !== ''
      }

      if (this.type === 'email') {
        valid = this.emailValid
      }

      if (this.type === 'tel') {
        valid = this.validatePhone(this.value)
      }

      return valid
    }
  },
  methods: {
    setFocusForSiblingInput(ref) {
      this.$refs[ref].focus()
    },
    setFocus: function (val) {
      this.focus = val

      if (val === false) {
        this.validate()
      } else {
        this.inputError = false
      }
    },
    onInput(value) {
      this.$emit('input', value)
    },
    validate: function () {
      if (this.type === 'email') {
        this.validateEmail()
      }

      if (this.validated) {
        this.inputError = false
      } else {
        this.inputError = true
      }
      return this.inputError;
    },
    validatePhone: function(number) {
      let re = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm
      return number.length > 0 && re.test(number)
    }
  }
}
</script>
