export default {
  data() {
    return {
      value: '',
      emailError: false,
      emailInvalidMessage: 'This email is invalid'
    }
  },
  computed: {
    emailValid() {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.value);
    }
  },
  methods: {
    validateEmail() {
      this.emailError = this.value.length > 0 && !this.emailValid;
    }
  }
};
