<template>
  <div class="notifications">
    <div class="container">
      <transition-group class="row justify-content-center" tag="div" name="notification-list" appear>
        <div v-for="notification in notifications" class="col-12 col-md-10 notifications__notification-container position-relative" v-bind:key="notification" >
          <div class="d-flex justify-content-center">
            <Notification :text="notification" v-on:remove="removeNotification"/>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import Notification from "./Notification";
export default {
  name: "Notifications",
  components: {Notification},
  data() {
    return {
      notifications: [],
      queue: [],
      batchJob: false
    }
  },
  watch: {
    queue() {
      if(!this.batchJob) this.startBatchJob()
    }
  },
  methods: {
    addNotification: function(text) {
      if(this.notifications.indexOf(text) === -1 && this.queue.indexOf(text) === -1) this.queue.push(text)
    },
    removeNotification: function (text) {
      this.notifications = this.notifications.filter(e => e !== text);
    },
    removeAllNotifications: function () {
      this.notifications = []
    },
    startBatchJob: function (){
      this.batchJob = window.setInterval(() => {
        if(this.queue.length > 0)
        {
          this.notifications.push(this.queue.shift())
        } else
        {
          window.clearInterval(this.batchJob)
          this.batchJob = false
        }
      }, 500)
    }
  }
}
</script>