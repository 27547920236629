import { getFirestore, collection, addDoc, query, getDocs, where, limit } from "firebase/firestore";

const db  = getFirestore();

export default {

    async generateCodes(amount, length) {
        let codes = []
        while (codes.length <= amount)
        {
            let code = generateCode(length)
            if(!codes.includes(code)) {
                codes.push(code)
                await create({code: code})
            }
        }
        return true
    },
    async createUsedCode(code, cookie, time) {
        try {
            await addDoc(collection(db, "used-codes"), {code: code, cookie: cookie, time: time});
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    },
    async isCodeValid(code) {
        let codeValid = false
        let q = query(collection(db, "codes"), where('code', '==', code), limit(1));
        let querySnapshot = await getDocs(q);
        codeValid = !querySnapshot.empty
        if (codeValid)
        {
            q = query(collection(db, "used-codes"));
            querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                if (doc.data().code === code) codeValid = false
            });
        }
        return codeValid
    },
    async getAllCodes() {
        let codes = []
        let q = query(collection(db, "codes"));
        let querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            codes.push(doc.data().code)
        });
        return codes
    }
}

async function create(data) {
    await addDoc(collection(db, "codes"), data);
}

function generateCode(length) {
    let text = "";
    let possible = "ABCDEFGHJKLMNPQRSTUVWXYZ23456789";

    for (let i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}