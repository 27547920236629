<template>
  <div class="form-checkbox" :class="{'form-checkbox--disabled' : (numberOfToppings >= 5  && !isChecked)}">
    <div class="form-checkbox__inner">
      <input
          class="form-checkbox__input"
          type="checkbox"
          :id="_uid + '-' + id"
          :checked="isChecked"
          :value="value"
          :disabled="numberOfToppings >= 5  && !isChecked"
          @change="updateInput">
      <label :for="_uid + '-' + id" class="form-checkbox__label" v-html="label"></label>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "FormCheckbox",
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    id: {
      type: String
    },
    value: {
      type: String
    },
    modelValue: {
      default: ""
    },
    label: {
      type: String,
      required: true
    },
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    }
  },
  computed: {
    ...mapGetters('cookie', {
      numberOfToppings: 'numberOfToppings'
    }),
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue
    }
  },
  methods: {
    updateInput(event) {
      let isChecked = event.target.checked
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue]
        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }
        this.$emit('change', newValue)
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue)
      }
    }
  }
}
</script>
