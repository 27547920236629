<template>
  <div></div>
</template>

<script>
import ordersApi from "../components/api/order"

export default {
  name: "CodesExporter",
  async mounted() {
    let orders = []
    try {
      let allOrders = await ordersApi.getAllOrders()
      for (let i = 0; i < allOrders.length; i++)
      {
        let toppings = "="
        for(let j = 0; j < allOrders[i].cookie.toppings.length; j++)
        {
          toppings += `""${allOrders[i].cookie.toppings[j]}""&char(10)&`
        }
        orders.push({
          firma: allOrders[i].contact.company,
          vorname: allOrders[i].contact.fname,
          nachname: allOrders[i].contact.lname,
          adresse: allOrders[i].contact.address,
          plz: allOrders[i].contact.zip,
          ort: allOrders[i].contact.place,
          email: allOrders[i].contact.email,
          versandmethode: allOrders[i].shipping,
          guetzli: allOrders[i].cookie.type,
          glasur: allOrders[i].cookie.icing,
          toppings: toppings,
        })
      }
      let csvContent = "data:text/csv;charset=utf-8," + this.jsonArrayToCSV(orders)
      window.open(encodeURI(csvContent))
    } catch (e) {
      await this.$router.push({name: 'home'})
    }
  },
  methods: {
    jsonArrayToCSV: function (array) {
      let fields = Object.keys(array[0])
      let replacer = function(key, value) { return value === null ? '' : value }
      let csv = array.map(function(row){
        return fields.map(function(fieldName){
          return JSON.stringify(row[fieldName], replacer)
        }).join(',')
      })
      csv.unshift(fields.join(',')) // add header column
      csv = csv.join('\r\n');
      return csv
    }
  }
}
</script>