<template>
  <div class="cookie-counter">
    <div class="cookie-counter__wrapper d-flex flex-column align-items-center">
      <div class="cookie-counter__counter d-flex flex-column justify-content-center align-items-center" ref="counter">
        <div>0{{ numberOfToppings }}</div>
        <div class="cookie-counter__counter-wiggle"></div>
        <div>05</div>
      </div>
      <div class="cookie-counter__description" ref="description">
        zutaten
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CookieCounter",
  computed: {
    ...mapGetters('cookie', {
      numberOfToppings: 'numberOfToppings'
    })
  },
  watch: {
    numberOfToppings: function () {
      this.$refs.counter.classList.remove("cookie-counter__counter--wobble");
      void this.$refs.counter.offsetWidth;
      this.$refs.counter.classList.add("cookie-counter__counter--wobble");

      this.$refs.description.classList.remove("cookie-counter__description--wobble");
      void this.$refs.description.offsetWidth;
      this.$refs.description.classList.add("cookie-counter__description--wobble");
    }
  }
}
</script>