<template>
  <div id="home">
    <header-big />
    <form-cookie />
  </div>
</template>

<script>
import HeaderBig from "../components/HeaderBig";
import FormCookie from "../components/CookieForm";
export default {
  name: 'Home',
  components: {FormCookie, HeaderBig}
}
</script>
