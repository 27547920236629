<template>
  <span v-observe-visibility="{callback: visibilityChanged, once: true}">
    {{ currentNumberString }}
  </span>
</template>

<script>

export default {
  name: "NumberCounter",
  props: {
    number: {
      type: Number,
      validator: value => {
        // Only accepts positive Integer numbers
        return Number.isInteger(value) && value >= 0;
      }
    },
    animationTotalTimeMs: {
      type: Number,
      default: 2000
    },
    animationStepMs: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      targetNumber: Math.round(this.number),
      currentNumber: 0
    }
  },
  computed: {
    currentNumberString() {
      return Math.round(this.currentNumber).toString();
    },
    step() {
      return this.targetNumber / this.animationSteps;
    },
    animationSteps() {
      return (this.animationTotalTimeMs / this.animationStepMs);
    }
  },
  methods: {
    visibilityChanged (isVisible) {
      if (isVisible) {
        this.updateNumber();
      }
    },
    updateNumber() {
      if (this.currentNumber <= this.targetNumber + this.step) {
        this.currentNumber += this.step;

        setTimeout(this.updateNumber, this.animationStepMs);
        return;
      }
      // finally set the current number to the target number (in case we do not fully meet the target)
      this.currentNumber = this.targetNumber;
    }
  }
}
</script>

<style scoped>

</style>
