<template>
  <div class="cookie position-relative" v-observe-visibility="{callback: visibilityChanged, once: true}">
    <div v-if="number" :class="{'cookie__circle--visible' : visible}" class="cookie__circle"></div>
    <img class="cookie__image img-fluid" :class="{'cookie__image--visible' : visible}" :src="getRndCookie(data.type)" alt="">
    <div v-if="number" class="cookie__text" :class="{'cookie__text--visible' : visible}">dein 🍪 ist<br>nummer {{ number }}</div>
  </div>
</template>

<script>
export default {
  name: "Cookie",
  props: {
    data: Object,
    number: Number,
  },
  data() {
    return {
      visible: false,
      delay: 100
    }
  },
  mounted() {
    this.delay = this.getRndInt(50, 500)
  },
  methods: {
    getRndCookie: function (type) {
      if (type === 'Vanille') return require(`../assets/images/cookies/cookie_hell_${this.getRndInt(1, 3)}.png`)
      if (type === 'Schoggi') return require(`../assets/images/cookies/cookie_dunkel_${this.getRndInt(1, 3)}.png`)
      if (type === 'Spekulatius') return require(`../assets/images/cookies/cookie_mittel_${this.getRndInt(1, 3)}.png`)
      return ""
    },
    getRndInt: function (min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    visibilityChanged (isVisible) {
      if (isVisible) {
        setTimeout(() => {
          this.visible = true
        }, this.delay + (this.number ? 1000 : 0));
      }
    }
  }
}
</script>