<template>
  <div class="code-generator">
    <h1>Generating {{ numberOfCodes }} Codes:</h1>
    <h3>Status: {{ !finished ? 'generating' : `finished, ${numberOfCodes} Codes generated` }}</h3>
  </div>
</template>

<script>
import api from "../components/api/codes"

export default {
  name: "CodeGenerator",
  data() {
    return {
      numberOfCodes: false,
      finished: false
    }
  },
  async mounted() {
    this.numberOfCodes = this.$route.params.amount
    try {
      this.finished = await api.generateCodes(this.numberOfCodes, 8)
    } catch (e) {
      await this.$router.push({name: 'home'})
    }
  }
}
</script>