<template>
  <div class="header-big">
    <div class="header-big__background">
      <div class="header-big__background-image"></div>
      <img src="../assets/images/mehl.png" alt="logo cookies" class="header-big__deko--desktop">
      <img src="../assets/images/mehl.png" alt="logo cookies" class="header-big__deko--mobile">
      <div class="header-big__background-border"></div>
      <div class="noise"></div>
    </div>

    <a href="https://seerow.ch/" target="_blank" rel="noreferrer" class="header-big__link">
      <img src="../assets/images/seerow_logo.svg" alt="seerow logo" class="header-big__link-logo">
    </a>

    <div class="header-big__cookie-container">
      <img src="../assets/images/cookie_header_dektop.png" alt="logo cookies" class="header-big__image--desktop img-fluid">
      <img src="../assets/images/cookie_header_mobile.png" alt="logo cookies" class="header-big__image--mobile img-fluid">
    </div>

    <div class="header-big__title-box">
      <h1 class="header-big__title">trau dich und stelle dein custom-guetzli* zusammen!</h1>
      <p class="header-big__subtitle">*auf eigene verantwortung!</p>
    </div>

    <div class="header-big__button-box">
      <button class="header-big__button"
              @click="goto('form-cookie')">
        konfigurieren
      </button>
    </div>

  </div>
</template>

<script>
export default {
  name: "HeaderBig",
  methods: {
    goto(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth"
      });
    }
  }
}

</script>

<style scoped>


</style>