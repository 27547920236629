<template>
  <div class="form-radio-button">
    <div class="form-radio-button__inner">
      <input
          class="form-radio-button__input"
          type="radio"
          :checked="isChecked"
          :value="value"
          :id="_uid + '-' + id"
          @change="$emit('change', $event.target.value)" >
      <label :for="_uid + '-' + id" class="form-radio-button__label" v-html="label"></label>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormRadioButton",
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      default: "",
      required: true
    },
    modelValue: {
      default: ""
    },
    value: {
      type: String,
      default: undefined
    },
    id: {
      type: String,
      default: undefined
    }
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value
    }
  }
}
</script>
