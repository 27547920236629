<template>
  <div class="form-cookie" id="form-cookie">
    <div class="form-cookie__form position-relative">
      <div class="form-cookie__background-wrapper">
        <div class="container position-relative h-100">
          <div class="row">
            <div class="form-cookie__background-container">
              <div class="form-cookie__background"></div>
              <div class="noise"></div>
            </div>
            <background-decoration />
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">

          <div class="form-cookie__block col-12">
            <div class="d-flex justify-content-center">
              <h2 class="form-cookie__block-title">guetzli teig</h2>
            </div>
            <div class="row">
              <div class="col-12 offset-md-1 col-md-10">
                <div class="row">
                  <div class="col-12 col-md-6 form-cookie__input-element">
                    <div class="d-flex justify-content-center turn-me">
                      <form-radio-button id="chocolate" label="schoggi guetzli" value="Schoggi"
                                         v-model="cookieType"/>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 form-cookie__input-element">
                    <div class="d-flex justify-content-center turn-me">
                      <form-radio-button id="vanilla" label="vanille guetzli" value="Vanille"
                                         v-model="cookieType"/>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 form-cookie__input-element">
                    <div class="d-flex justify-content-center turn-me">
                      <form-radio-button id="speculoos" label="spekulatius guetzli" value="Spekulatius"
                                         v-model="cookieType"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-cookie__block col-12">
            <div class="d-flex justify-content-center">
              <h2 class="form-cookie__block-title">glasur</h2>
            </div>
            <div class="row">
              <div class="col-12 offset-md-1 col-md-10">
                <div class="row">
                  <div class="col-12 col-md-6 form-cookie__input-element">
                    <div class="d-flex justify-content-center turn-me">
                      <form-radio-button id="chocolate-white" label="schoggi überzug (weiss)"
                                         value="Schoggi Weiss" v-model="icing"/>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 form-cookie__input-element">
                    <div class="d-flex justify-content-center turn-me">
                      <form-radio-button id="chocolate-milk" label="schoggi überzug (milch)"
                                         value="Schoggi Milch" v-model="icing"/>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 form-cookie__input-element">
                    <div class="d-flex justify-content-center turn-me">
                      <form-radio-button id="chocolate-dark" label="schoggi überzug (dunkel)"
                                         value="Schoggi Dunkel" v-model="icing"/>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 form-cookie__input-element">
                    <div class="d-flex justify-content-center turn-me h-100 align-items-center">
                      <form-radio-button id="sugar" label="zuckerglasur" value="Zuckerglasur"
                                         v-model="icing"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-cookie__toppings position-relative">
            <div class="form-cookie__counter-container">
              <cookie-counter />
            </div>

            <div class="form-cookie__block col-12">
              <div class="d-flex justify-content-center">
                <h2 class="form-cookie__block-title">belag</h2>
              </div>
              <div class="row">
                <div class="col-12 offset-md-1 col-md-10">
                  <div class="row">
                    <div class="col-12 col-md-6 form-cookie__input-element">
                      <div class="d-flex justify-content-center turn-me">
                        <form-checkbox label="gummibärli" value="Gummibärli" id="gummibear" v-model="topping"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 form-cookie__input-element">
                      <div class="d-flex justify-content-center turn-me">
                        <form-checkbox label="caramel-würfeli" value="Caramel-Würfeli" id="caramel-cubes"
                                       v-model="topping"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 form-cookie__input-element">
                      <div class="d-flex justify-content-center turn-me">
                        <form-checkbox label="smarties" value="Smarties" id="smarties" v-model="topping"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 form-cookie__input-element">
                      <div class="d-flex justify-content-center turn-me">
                        <form-checkbox label="rainbow streusel" value="Rainbow Streusel" id="rainbow-sprinkles"
                                       v-model="topping"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 form-cookie__input-element">
                      <div class="d-flex justify-content-center turn-me">
                        <form-checkbox label="cornflakes" value="Cornflakes" id="cornflakes" v-model="topping"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 form-cookie__input-element">
                      <div class="d-flex justify-content-center turn-me">
                        <form-checkbox label="saure zungen" value="Saure Zungen" id="sour-patch"
                                       v-model="topping"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 form-cookie__input-element">
                      <div class="d-flex justify-content-center turn-me">
                        <form-checkbox label="pekan nüsse" value="Pekan Nüsse" id="pecan-nuts" v-model="topping"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 form-cookie__input-element">
                      <div class="d-flex justify-content-center turn-me">
                        <form-checkbox label="marshmallow" value="Marshmallow" id="marshmallow"
                                       v-model="topping"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-cookie__block col-12">
              <div class="d-flex justify-content-center">
                <h2 class="form-cookie__block-title">extra</h2>
              </div>
              <div class="row">
                <div class="col-12 offset-md-1 col-md-10">
                  <div class="row">
                    <div class="col-12 col-md-6 form-cookie__input-element">
                      <div class="d-flex justify-content-center turn-me">
                        <form-checkbox label="fleur de sel" value="Fleur de Sel" id="fleur-de-sel"
                                       v-model="topping"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 form-cookie__input-element">
                      <div class="d-flex justify-content-center turn-me">
                        <form-checkbox label="essig chips" value="Essig Chips" id="vinegar-chips" v-model="topping"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 form-cookie__input-element">
                      <div class="d-flex justify-content-center turn-me">
                        <form-checkbox label="e spruz sriracha" value="Sprutz Sriracha" id="sriracha"
                                       v-model="topping"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 form-cookie__input-element">
                      <div class="d-flex justify-content-center turn-me">
                        <form-checkbox label="überrasch&shy;ungszutat" value="Überraschungszutat" id="surprise" v-model="topping"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-cookie__contact-block col-12 offset-md-2 col-md-8">
            <div class="form-cookie__contact-block-wrapper">
              <div class="d-flex justify-content-center">
                <h3 class="form-cookie__contact-title">bestellung</h3>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 form-cookie__contact-element order-1 order-md-0">
                  <form-input label="firma" type="text" v-model="contact.company"
                              :required="false" name="company"
                              :error-message="emptyRequiredFieldMessage"
                              ref="company"></form-input>
                </div>
                <div class="col-12 col-md-6 form-cookie__contact-element d-flex align-items-center order-0">
                  <div v-if="codeValid" class="form-cookie__contact-code">Code: <b>{{ code }}</b></div>
                  <form-input v-else label="persönlicher code" type="text" v-model="codeInput"
                              :required="true" name="code"
                              :error-message="emptyRequiredFieldMessage"
                              ref="code"></form-input>
                </div>
                <div class="col-12 col-md-6 form-cookie__contact-element">
                  <form-input label="vorname" type="text" v-model="contact.fname"
                              :required="true" name="fname"
                              :error-message="emptyRequiredFieldMessage"
                              ref="fname"></form-input>
                </div>
                <div class="col-12 col-md-6 form-cookie__contact-element">
                  <form-input label="nachname" type="text" v-model="contact.lname"
                              :required="true" name="lname"
                              :error-message="emptyRequiredFieldMessage"
                              ref="lname"></form-input>
                </div>
                <div class="col-12 col-md-6 form-cookie__contact-element">
                  <form-input label="email" type="email" v-model="contact.email"
                              :required="true" name="email"
                              :error-message="emptyRequiredFieldMessage"
                              ref="email"></form-input>
                </div>
                <div class="col-12 col-md-6 form-cookie__contact-element">
                  <form-input label="adresse" type="text" v-model="contact.address"
                              :required="true" name="address"
                              :error-message="emptyRequiredFieldMessage"
                              ref="address"></form-input>
                </div>
                <div class="col-12 col-md-6 form-cookie__contact-element">
                  <form-input label="postleitzahl" type="number" v-model="contact.zip"
                              :required="true" name="zip"
                              :error-message="emptyRequiredFieldMessage"
                              ref="zip"></form-input>
                </div>
                <div class="col-12 col-md-6 form-cookie__contact-element">
                  <form-input label="ort" type="text" v-model="contact.place"
                              :required="true" name="place"
                              :error-message="emptyRequiredFieldMessage"
                              ref="place"></form-input>
                </div>
              </div>

              <div class="d-flex justify-content-center">
                <h3 class="form-cookie__contact-title form-cookie__contact-title--second">versand</h3>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="d-flex justify-content-start">
                    <form-radio-button class="form-radio-button--small" id="pickup" label="abholung bei uns im büro" value="Abholen"
                                       v-model="shipping"/>
                  </div>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-center">
                  <div class="d-flex justify-content-start">
                    <form-radio-button class="form-radio-button--small" id="shipping" label="versand per post" value="Versenden"
                                       v-model="shipping"/>
                  </div>
                </div>
              </div>

              <div class="form-cookie__submit">
                <button class="button button--standard turn-me" v-on:click="createOrder">bestellen</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <notifications ref="notifications" />
    <cookie-tray v-if="showCookieTray" />
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import FormInput from "./FormInput";
import FormRadioButton from "./FormRadioButton";
import FormCheckbox from "./FormCheckbox";
import api from "./api/order"
import codesApi from "./api/codes"
import Notifications from "./Notifications";
import CookieTray from "./CookieTray";
import CookieCounter from "./CookieCounter";
import BackgroundDecoration from "./BackgroundDecoration";

export default {
  name: "FormCookie",
  components: {BackgroundDecoration, CookieCounter, CookieTray, Notifications, FormCheckbox, FormRadioButton, FormInput},
  data() {
    return {
      code: false,
      codeValid: false,
      codeInput: "",
      cookieType: false,
      icing: false,
      topping: [],
      shipping: false,
      contact: {
        fname: "",
        lname: "",
        company: "",
        email: "",
        address: "",
        zip: "",
        place:  ""
      },
      emptyRequiredFieldMessage: "Bitte Feld ausfüllen",
      showCookieTray: false
    }
  },
  async mounted() {
    this.code = this.$route.params.code
    this.codeValid = (this.code !== undefined && this.code.length === 8 && (await codesApi.isCodeValid(this.code)))

    if(this.$route.params.fname)this.contact.fname = this.$route.params.fname
    if(this.$route.params.lname)this.contact.lname = this.$route.params.lname

    Array.from(document.getElementsByClassName('turn-me')).forEach(item => {
      this.changeRotation(item)
    })
  },
  watch: {
    topping: function (val) {
      this.updateToppings(val)
    }
  },
  methods: {
    ...mapActions('cookie', [
      'updateToppings'
    ]),
    async createOrder() {
      let errorOccurred = false

      let date = new Date()
      this.$refs.notifications.addNotification(`Die Seerow 🍪-Fabrik ist leider bis auf weiteres geschlossen. Bitte versuchen Sie es im Dezember ${date.getUTCFullYear()} erneut.`)
      errorOccurred = true

      if(!this.cookieType)
      {
        //this.$refs.notifications.addNotification("bitte wähle einen guetzli teig")
        errorOccurred = true
      }

      if(!this.icing)
      {
        //this.$refs.notifications.addNotification("bitte wähle eine guetzli glasur")
        errorOccurred = true
      }

      if(this.$refs.email.emailValid)
      {
        for(var key in this.contact) {
          if(key !== 'company' && this.contact[key] === "") {
            //this.$refs.notifications.addNotification("bitte überprüfe deine angaben und fülle alle felder korrekt aus")
            errorOccurred = true
          }
        }
      } else {
        //this.$refs.notifications.addNotification("bitte gib eine gültige email-adresse an")
        errorOccurred = true
      }

      if(!this.shipping)
      {
        //this.$refs.notifications.addNotification("bitte wähle eine versand methode")
        errorOccurred = true
      }

      if(!this.codeValid && !await codesApi.isCodeValid(this.codeInput))
      {
        //this.$refs.notifications.addNotification("dein code ist leider ungültig oder wurde bereits eingelöst. bei fragen: <a href='mailto:support@seerow.ch '>support@seerow.ch<a/>")
        //this.$refs.notifications.addNotification("You are on the naughty list this year, santa-seerow sadly doesnt have a 🍪 for you 😢<br>no valid code = no 🍪")
        //this.$refs.notifications.addNotification("der eingegebene Code ist leider ungültig oder wurde bereits verwendet, 🎅🏼-seerow hat leider kein 🍪 für dich dieses Jahr 😢<br>no valid code = no 🍪")
        errorOccurred = true
      }

      if(errorOccurred) return

      let order = {
        contact: this.contact,
        cookie: {
          type: this.cookieType,
          icing: this.icing,
          toppings: this.topping
        },
        shipping: this.shipping,
        time: new Date().getTime()
      }

      await api.create(order)

      if (this.codeValid) await codesApi.createUsedCode(this.code, order.cookie, order.time)
      else await codesApi.createUsedCode(this.codeInput, order.cookie, order.time)

      this.resetForm()
      this.$refs.notifications.removeAllNotifications()

      setTimeout(() => {
        this.showCookieTray = true
      }, 50);
    },
    resetForm: function () {
      this.code = ""
      this.codeInput = ""
      this.codeValid = false
      this.cookieType = false
      this.icing = false
      this.topping = []
      this.shipping = false
      this.contact.fname = ""
      this.contact.lname = ""
      this.contact.email = ""
      this.contact.address = ""
      this.contact.zip = ""
      this.contact.place = ""
      this.contact.company = ""
      this.showCookieTray = false
    },
    changeRotation(self) {
      //self.style.transition = 'transform .4s ease'
      self.style.transformOrigin = 'center'
      self.style.transform = `rotate(${ (Math.random() - 0.5) * 10 }deg)`
    }
  }
}
</script>