<template>
  <div v-if="cookies" class="cookie-tray">
    <div class="container position-relative">
      <div class="cookie-tray__background">
        <div class="cookie-tray__background-image"></div>
        <div class="cookie-tray__background-border"></div>
        <div class="noise"></div>
      </div>

      <div class="row cookie-tray__row">
        <div class="col-12">
          <div class="d-flex justify-content-center">
            <h2 class="cookie-tray__title"><number-counter :number="cookies.length" /> 🍪 kreiert</h2>
          </div>
        </div>

        <div v-for="(cookie, key) in cookies" class="cookie-tray__cookie col-4 col-sm-3 col-lg-2"
             v-bind:key="'cookie_' + key">

          <cookie :data="cookie" :number="key === cookies.length - 1 ? cookies.length : null"/>
        </div>
      </div>
    </div>
    <div id="bottom" class="position-relative"></div>
  </div>
</template>

<script>
import ordersApi from "./api/order"
import NumberCounter from "./NumberCounter";
import Cookie from "./Cookie";
//import scrolleroni from "vue-scrollto"

export default {
  name: "CookieTray",
  components: {Cookie, NumberCounter},
  data() {
    return {
      cookies: false,
      isScrolledDown: false
    }
  },
  async beforeMount() {
    this.cookies = await ordersApi.getAllCookies()
  },
  updated() {
    this.scrollDown(5000)
    //scrolleroni.scrollTo(document.getElementById("bottom"), 5000, {easing: 'ease-in-out'})
  },
  methods: {
    scrollDown(duration) {
      let start, previousTimeStamp;
      let pageOffset = window.pageYOffset

      function lerp(start, end, t) {
        return (1 - t) * start + t * end
      }

      function step(timestamp) {
        if (start === undefined)
          start = timestamp;
        const elapsed = timestamp - start;

        if (previousTimeStamp !== timestamp) {
          let t = elapsed / duration;
          t = t * t * (3 - 2 * t);

          var limit = Math.max(document.body.scrollHeight, document.body.offsetHeight,
              document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - window.innerHeight;

          document.documentElement.scrollTop = lerp(pageOffset, limit, t)
        }

        if (elapsed < duration) { // Stop the animation after 2 seconds
          previousTimeStamp = timestamp
          window.requestAnimationFrame(step);
        }
      }

      window.requestAnimationFrame(step);
    }
  }
}
</script>