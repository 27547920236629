import {getFirestore, collection, addDoc, query, getDocs} from "firebase/firestore";

const db  = getFirestore();

export default {

    async create(data) {
        try {
            await addDoc(collection(db, "orders"), data);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    },
    async getAllCookies() {
        let cookies = []
        let q = query(collection(db, "used-codes"));
        let querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            cookies.push({
                type: doc.data().cookie.type,
                time: doc.data().time
            })
        });
        cookies.sort((a, b) => {
            if (a.time < b.time) return -1
            if (a.time > b.time) return 1
            return 0
        })
        return cookies
    },
    async getAllOrders() {
        let orders = []
        let q = query(collection(db, "orders"));
        let querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            orders.push(doc.data())
        });
        return orders
    }
}